.modalButton {
    margin-top: 0.6rem;
}

.inputTitle {
    font-weight: bold;
}

.minimalTime {
    margin-top: -2em;
}

.inline {
    display: inline-block;
    width: 100%;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
}