.modalLabel {
    color: #9b9c9e;
    font-size: 4.38rem;
    font-weight: 600;
}

.modalLabel span {
    margin-left: 0.5rem;
    font-size: 0.6rem;
    font-weight: 200;
}

.modalButton {
    margin-top: 1rem;
}

.alreadyUsedConcreteButton {
    margin-top: 1rem;
}

.undoneNoteLabel {
    color: #9b9c9e;
    font-style: italic;
}

.radioIcon {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-wrap: wrap;
}

.radioIcon p {
    flex: 1 1 60%;
    text-align: center;
    min-width: 5.125rem;
}