.listHeader {

    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.12rem;
    line-height: 1.5rem;

    /* Gray / gray-9 */
    color: #262626;

}

.listBody {
    
    background: #FFFFFF;

    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.12rem;
    line-height: 1.5rem;
    
    color: #262626;

}

.listItem {

    background: #FFFFFF;

    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: .87rem;
    line-height: 1.37rem;

    color: #595959;

}

.listComment {
    
    background: #FFFFFF;

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: .87rem;
    line-height: 1.37rem;

    color: #8C8C8C;

}

.listElemUser {

    background: #FFFFFF;

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: .74rem;
    line-height: 1.24rem;

    color: #595959;

}
