.ant-pagination {
  font-size: @pagination-font-size;
}

.ant-pagination-item {
  &.ant-pagination-item-active {
    a {
      color: @pagination-item-color-active;
    }
  }
  border-radius: 4px;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  border-radius: 4px;
  font-size: @pagination-font-size;
}
