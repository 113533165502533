.cardTitle {
    /* Gray/gray-9 */
    color: #262626;

    font-family: Open Sans, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;

}

.statusTag {
    /* Gray/gray-9 */
    color: #ffffff;
    border-radius: 4px;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.supplierOrderId {
    /* Gray/gray-9 */
    color: #262626;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}


.overviewTitle {
    /* Gray/gray-9 */
    color: #262626;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;

}

.overviewRowCard {
    /* Gray/gray-9 */
    color: #262626;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.overviewRowCol {
    /* Gray/gray-9 */
    color: #FFFFFF;

    height: 38px;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;

    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.overviewBreak {
    border: none;
    height: 1px;
    color: #f0f0f0;
    /* old IE */
    background-color: #f0f0f0;
    /* Modern Browsers */
}

.barCol {

    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

    padding: 6px;

}

.overviewLegendCol {
    height: 32px;
}

.cardLegendCol {
    height: 18px;
    color: #262626;

    font-family: Open Sans, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.chartBar:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.chartBar:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.cardCircle {

    width: .94rem;
    height: .94rem;
    border-radius: 50%;

}