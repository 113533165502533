.info-header {
  color: #8C969D;
  text-transform: uppercase;
}
.info-body {
  color: #8C969D;
}
.info-bold {
  font-weight: 700;
  color: #024A94;
}
