.modalLabel  {
    color: #1f2022;
    font-weight: bold;
}

.modalLabel  span {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    font-weight: normal;
}
