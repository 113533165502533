@form-text-color: #8C969D;

.ant-form-item {
  margin-bottom: 34px;
}

nz-time-picker, nz-date-picker {
  &.ant-picker {
    width: 100%;
    padding: 3px 11px;
  }
}

nz-select-top-control {
  &.ant-select-selector {
    width: unset;
    height: unset;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.ant-select-selection-search {
  width: unset;
  height: unset;
  padding: 3px 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

input, button, select, optgroup, textarea {
  &.ant-input {
    font-family: @font-family;
    color: @form-text-color;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: @form-text-color;
    }
  }
  &.ant-select-selection-placeholder {
    color: @form-text-color;
  }
}

.ant-picker-input {
  input {
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: @form-text-color;
    }
  }
}

.ant-input-affix-wrapper, .ant-input-group-addon {
  color: @form-text-color;
}

.form-header {
  color: @primary-color;
  font-weight: bold;
  font-size: 16pt;
}
