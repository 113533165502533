// Utilities
.util-btn-hover(@color) {
    background-color: darken(@color, 10%);
    border-color: darken(@color, 10%);
}

.util-btn-disabled() {
    background-color: darken(#ffffff, 10%);
}

// Button Styles
.ant-btn:not(.ant-btn-icon-only):not(.ant-btn-sm) {
    @media @tablet {
        text-transform: uppercase;
        border-radius: 4px;
        padding: 18px 16px;
        height: 60px;
        font-weight: 600;
    }

    text-transform: uppercase;
    font-weight: 600;
    border-radius: 6px;
    padding: 8px 16px;
    height: 40px;
    font-size: 14px;

    &.ant-btn-link {
        font-size: 12pt;
        padding: 0;
    }
}

.actions {
  .ant-btn-sm {
    margin-right: 8px;
    font-weight: 300;
    height: auto;
  }
}

// Interaction
.ant-btn-primary {
    &:hover {
        .util-btn-hover(@primary-color);
    }

    &:focus {
        .util-btn-hover(@primary-color);
        box-shadow: 0px 0px 0px 3px rgba(2, 74, 148, 0.3);
    }

    &:disabled {
        .util-btn-disabled();
    }
}

// AKA Secondary button
nz-modal-container .ant-btn:nth-child(1) {
    &:hover {
        .util-btn-hover(#ffffff);
        color: black;
    }

    &:focus {
        .util-btn-hover(#ffffff);
        color: black;
        box-shadow: 0px 0px 0px 3px rgba(140, 150, 157, 0.3);
    }

    &:disabled {
        .util-btn-disabled();
    }
}

.ant-btn-icon-only {
  width: unset;
  height: unset;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  &.custom-search-button {
    height: 33px;
  }
}



.ant-btn > .anticon {
    line-height: 0;
}
