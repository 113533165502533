.step {
    font-weight: bold;
}

.title {
    font-weight: bold;
}

.refenceContainer {
    color: #024A94;
    cursor: pointer;
}

.refenceContainer img {
    display: none;
}


.refenceContainerActive {
    padding: 10px;
    border: 1px solid #B3C9DF;
    background: linear-gradient(0deg, #E6EDF4, #E6EDF4),
        linear-gradient(0deg, #B3C9DF, #B3C9DF);
}

.refenceContainerActive img {
    display: block;
    width: 426px;
}

.refenceTitle {
    margin-bottom: 10px;
}

.refenceTitle:hover {
    text-decoration: underline;
}

.refenceTitle span {
    margin-right: 10px;
}