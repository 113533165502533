.tableRowDanger {
    background-color: #FFF1F0;
    color: #FF4D4F;
}

.tableRowDanger:hover {
    color: black;
}

.tableRow {
}

.tableTitle {
    margin-right: 1em;
    font-weight: bold;
}
