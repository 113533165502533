.ant-table-thead > tr > th {
    /* Bold/14px | 22px */
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;

    color: #262626;
  }
  
  .ant-tag.ng-star-inserted {
    font-size: 8pt;
    text-transform: uppercase;
    padding: 2px 8px;
    border-radius: 4px;
  }
  
  .ant-table {

    a {
      text-transform: uppercase;
      font-weight: 600;
    }
  
    .ant-table-thead {
      font-size: @table-header-font-size;
    }
  }
  
  .ant-table-container {
    border-radius: 6px;
  }
  
  .table-filter {
    color: @text-color;
  }
  
  .ant-steps-item-description {
    max-width: 80% !important;
  }
  
  .ant-steps-item-content {
    width: 100% !important;
  }
  