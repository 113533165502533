.ant-modal-title {
    font-weight: bold;
}
.ant-modal-header {
  border-radius: @modal-header-radius;
  border-bottom: @modal-section-divider;
}

.ant-modal-content {
  border-radius: 6px;
}

.ant-modal-close-x {
  margin: 8px;
  font-weight: @btn-font-weight;
  color: @primary-color;
}

.ant-modal-body {
  font-size: 12pt;
}
