
.accept-sign__ant-radio-group {
    display: flex;
    justify-content: space-between;
}

.accept-sign__ant-radio-button .ant-radio-button-wrapper {
    height: 6.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 3.75rem;
    margin: 0 0.75rem;
    border: none;
}

.accept-sign__ant-radio-button .ant-radio-button-wrapper::before {
    position: relative;
}

.accept-sign__ant-radio-button .ant-radio-button-wrapper::hover {
    position: relative;
}

.accept-sign__ant-radio-button .ant-radio-button-wrapper::hover img {
    position: relative;
}

.accept-sign__ant-radio-button .ant-radio-button-checked {
    border: none;
}

.accept-sign__ant-radio-button .ant-radio-button-checked::before {
    color: transparent;
}

.accept-sign__ant-radio-button .ant-radio-button-checked::active {
    border: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) img {
    filter: sepia(100%) hue-rotate(190deg) saturate(500%) !important;
}

.ant-radio-group .ant-radio-button-wrapper .radio-icon {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-wrap: wrap;
}

.ant-radio-group .ant-radio-button-wrapper .radio-icon p {
    flex: 1 1 60%;
    text-align: center;
    min-width: 3.125rem;
}

.accept-and-sign__modal-label {
    color: #9b9c9e;
    font-size: 1em;
    font-weight: 600;
}

.modalabel span {
    margin-left: 0.31rem;
    font-size: 0.65rem;
    font-weight: 200;
}
