.radioGroup {
    display: flex;
    justify-content: space-between;
}

.radioGroup label {
    text-align: center;
    border: none;
    max-width: none !important;
    margin: 0pt !important;
    padding: 5px !important;
    height: 70px;
}

.radioGroup label:first-child {
    border: none;
}

.radioGroup label:before {
  display: none !important;
}
