.ant-tabs-tab {
  font-size: 12pt;
  color: #3e3f42;
  font-weight: 300;

  .ant-badge-count {
    font-size: 10pt;
    background: #F0F0F0;
    color: #848484;
    border: #F0F0F0;
    box-shadow: 0 0 0 0;
  }

  &.ant-tabs-tab-active {
      .ant-badge-count {
        background:#e6edf4;
        color: @tabs-card-active-color;
    }
  }
}

.ant-tabs {
  font-size: 12pt;
}
