.pageTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: rgba(0, 0, 0, 0.85);
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px .75rem;
}