.menuHeader {
  display: flex;
  background: #1F3855;
  justify-content:space-between;
  padding: 0;
}

.menuTitle {
  display: flex;
  gap: .5rem;
}

.menuLogo {
  height: 2rem;
  margin: 1rem;
  width: 10.87rem;
}

.menuLogo  img {
    display: flex;
}

.menuName {
  margin-top: .25rem;
  margin-left: 1.6rem;
  font-size: 1rem;
}

.menuName a {
  color: white;
  text-transform: uppercase;
  font-weight:600;
}

.menuRightIcons {
  background: transparent;
  border-color: transparent;
}

.menuRightIcons:focus {
  background: #eff2f536;
  border-radius: unset;
}

.menuRightIcons:hover {
  background: #eff2f536;
  border-radius: unset;
}

.menu {
  height: 100vh; 
}

.menuSidebar{
  color: white;
}

.menuSidebar a {
  color: #dddddd;
  font-size: 0.9rem;
}

.menuSidebar span {
  color: white;
}

.menuDropdown{
  color:white;
  padding-top: .6rem;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.menuDropdown p {
  font-size: .6rem
}

.menuContent {
  overflow: auto;
  padding: 1.5rem;
  min-height: 17.5rem;
}