.concreteLink {
    margin-top: .2em;
    margin-bottom: 1em;
    font-size: 1.3em;
    font-weight: bold;
}

.radioError {
    color: red;
}

.formColor {
    background-color: #ffffff;
}

.formButton {
    margin-right: 1rem;
}
