.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-left: 2px solid #fff;
}

.collapsed {
  min-width: 80px;
  background-color: #1F3855;
}

.expanded {
  min-width: 255px;
  max-width: 255px;
  background-color: #1F3855;
}

.custom-icon {
  display: flex;
  align-items: center;
  justify-self: center;
}

.ant-menu-dark {

  li {
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0!important;
    min-height: 60px;

    a {
      font-weight: 600;
      color: #ffffff;
    }
  }

  .ant-menu-item:hover {
    border-left: 2px solid #fff;
    background-color: rgba(255, 255, 255, 0.1);

  }

}


.footer-menu {
    position: absolute;
    bottom: 70px;
}
