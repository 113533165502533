#atlwdg-trigger {
  display: none;
}

.feedback-button {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-right: .7em;
  padding: 1em;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
}

.feedback-button:hover {
  background-color: darken(white, 10%);
  color: #1F3855;
}