.modalLabel {
    color: #9b9c9e;
    font-size: 4.38rem;
    font-weight: 600;
}

.modalLabel span {
    margin-left: 0.5rem;
    font-size: 0.6rem;
    font-weight: 200;
}

.modalButton {
    margin-top: 1rem;
}

.colInline {
    display: inline-block;
    width: 100%;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
}

.layoutConfirmation {
  background-color: white;
  border-color: white;   
  font-weight: bold;
}
