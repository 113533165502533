.modalLabel {
  color: #222323;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  font-style: normal;
}

.colWithValue {
    margin-bottom: 15px;
}