.title {
    font-weight: 600;
    font-size: 0.95em;
}

.description {
    font-size: 0.94rem;
    display: flex;
    flex-direction: column;
    width: inherit;
}

.description > * {
    margin-top: 0.5rem;
}