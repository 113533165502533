

.headerRow th:nth-child(1), .headerRow th:nth-child(2), .headerRow th:nth-child(3),
.secondHeaderRow th:nth-child(3), .secondHeaderRow th:nth-child(10), .secondHeaderRow th:nth-child(14),
.row td:nth-child(3), .row td:nth-child(10), .row td:nth-child(14)
{
 border-right: 1px solid #8C8C8C !important;
} 

.headerRowUS th:nth-child(1), .headerRowUS th:nth-child(2), .headerRowUS th:nth-child(3),
.secondHeaderRowUS th:nth-child(3), .secondHeaderRowUS th:nth-child(8), .secondHeaderRowUS th:nth-child(12),
.rowUS td:nth-child(3), .rowUS td:nth-child(8), .rowUS td:nth-child(12)
{
 border-right: 1px solid #8C8C8C !important;
}


.table p{
 margin: 0;
}