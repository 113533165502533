
.buttonIcon {
    color: #024A94;
}

.filterTitle {
    font-weight: bold;
    font-size: .5rem;
}

.filterArea {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
    margin-top:  .5rem;
}

.filterTag {
    font-size: 1rem;
    padding: .5rem;
    margin: .55rem;
}

.tagsArea {
    border: .5px solid rgb(166, 165, 165);
    background-color: white;
}