.headerRow:first-child th:not(:first-child), .headerRowUS:first-child th:not(:first-child) {
 background: #E6EDF4;
}


.headerRow th:nth-child(2), .headerRow th:nth-child(3), .headerRow th:nth-child(4), .headerRow th:nth-child(5),
.secondHeaderRow th:nth-child(2), .secondHeaderRow th:nth-child(11), .secondHeaderRow th:nth-child(16), .secondHeaderRow th:nth-child(18),
.row td:nth-child(3), .row td:nth-child(12), .row td:nth-child(17), .row td:nth-child(19) 
{
 border-right: 1px solid #8C8C8C !important;
} 

.headerRowUS th:nth-child(2), .headerRowUS th:nth-child(3), .headerRowUS th:nth-child(4), .headerRowUS th:nth-child(5),
.secondHeaderRowUS th:nth-child(2), .secondHeaderRowUS th:nth-child(9), .secondHeaderRowUS th:nth-child(14), .secondHeaderRowUS th:nth-child(16),
.rowUS td:nth-child(3), .rowUS td:nth-child(10), .rowUS td:nth-child(15), .rowUS td:nth-child(17)
{
 border-right: 1px solid #8C8C8C !important;
}


.table p{
 margin: 0;
}